<template>
    <div class="card columns">
      <b-tooltip class="column" type="is-primary is-light" position="is-bottom" multilined>
        <div class="column has-text-centered">
          <p class="title is-4" style="margin-bottom: 6px; ">Total Active User <br> Count</p>
          <p class="title is-3" style="color: #BC00C0; font-weight: 700;">{{ numberWithCommas(activeCount.activeUserCount) }}</p>
        </div>
        <template v-slot:content>
          <p class="has-text-centered subtitle m-4 is-6"><b>Number of users who have conducted transactions in the last 30
              days.</b></p>
        </template>
      </b-tooltip>
      <div class="with-vertical-line"></div>
      <b-tooltip class="column" type="is-primary is-light" position="is-bottom" multilined>
        <div class="column has-text-centered">
          <p class="title is-4" style="margin-bottom: 6px; ">Total Active <br> Beneficiaries</p>
          <p class="title is-3" style="color: #BC00C0; font-weight: 700;">{{ numberWithCommas(activeCount.activeBeneficiariesCount) }}</p>
        </div>
        <template v-slot:content>
          <p class="has-text-centered subtitle m-4 is-6"><b>Number of users who have received money in the last 30
              days.</b></p>
        </template>
      </b-tooltip>
    </div>
</template>

<script>
import NetworkManager from "@/network";

export default {
  name: "TotalActiveCount",
  data() {
    return {
      activeCount: {
        activeUserCount: 0.0,
        activeBeneficiariesCount: 0.0,
      }
    }
  },
  methods: {
    loadData: function () {
      const self = this
      setTimeout(() => {

        NetworkManager.apiRequest('api/dashboard/getActiveUsers', {}, function (e) {
          console.log(e);
          if (e.statusCode === 200) {
            self.activeCount = e.data
          }
          else {
            //
          }
        })
      }, 200)
    }
  },

  mounted() {
    this.loadData()
  }
}
</script>

<style scoped>
.with-vertical-line {
  border-right: 2px solid #ccc;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
