<template>
  <section>
    <nav-bar/>
    <div class="columns">
        <div class="column is-one-fifth is-narrow is-2" style="padding: 0; padding-left: 10px;">
        <side-bar style="height: 94vh; " />
      </div>
      <div class="column" style="padding: 0;">
        <app-main class="card app-main"/>
      </div>
    </div>
  </section>
</template>

<script>
import AppMain from "@/layout/components/AppMain"
import NavBar from "@/layout/components/NavBar"
import SideBar from "@/layout/components/SideBar"

export default {
  name: "Layout",
  components: {
    AppMain,
    NavBar,
    SideBar
  },
  // computed: {
  //   isAuditDashboardRoute() {
  //     return this.$route.name === 'AuditDashboard';
  //   },
  // },
  mounted() {
    const self = this

    // this.startTokenTimer()
    setTimeout(() => {
      self.hideFullScreenLoader()
    }, 500)
  }
}
</script>

<style scoped>
  .app-main {
    overflow: hidden;
    height: 92vh;
    width: 83vw;
    margin: 10px 10px 10px 0;
  }
</style>
