<template>
  <div style="margin: 5px;">
    <div>
      <div class="columns">
        <div class="column">
          <b-dropdown position="is-right" @input="loadData" :disabled="isTableLoading" class="is-pulled-right" v-model="selectedFilter" aria-role="list">
            <template #trigger>
              <b-button size="is-small" icon-right="menu-down">
                {{ selectedFilter.text }}
              </b-button>
            </template>

            <b-dropdown-item v-for="item in filters" :value="item" :key="item.value" aria-role="listitem">
              <span>{{ item.text }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <vue-apex-charts ref="lineChart" type="line" width="120%" height="170%" :options="chartOptions" :series="series"></vue-apex-charts>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import NetworkManager from "@/network";

const today = new Date();

export default {
  name: "TotalTransactionValueEchannelingGrowthChart",
  components: { VueApexCharts },
  data() {
    return {
      dateRange: [today, today],
      isTableLoading: false,
      selectedFilter: { value: 'YEARLY', text: 'YEARLY' },
      filters: [
        { value: 'YEARLY', text: 'Yearly' },
        { value: "MONTHLY", text: 'Monthly' },
        { value: "WEEKLY", text: 'Weekly' },
      ],
      series: [],
      chartOptions: {
        chart: {
          height: 250,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        colors: [],
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          },
        },
        xaxis: {
          categories: [],
        }
      },
    };
  },

  methods: {
    loadData() {
      const data = {
        range: this.selectedFilter.value
      };

      NetworkManager.apiRequest('api/dashboard/getEchTransactionTableData', data, (response) => {
        console.log(response)
        if (response.statusCode === 200) {
          this.handleResponse(response.data);
        } else {
          // Handle error
        }
      });
    },

    handleResponse(data) {
      switch (this.selectedFilter.value) {
        case 'YEARLY':
          this.handleYearlyResponse(data);
          break;
        case 'WEEKLY':
          this.handleWeeklyResponse(data);
          break;
        case 'MONTHLY':
          this.handleMonthlyResponse(data);
          break;
        case 'ALL':
          // Handle 'ALL' response
          break;
        default:
          // Handle unknown filter type
          break;
      }
    },

    handleYearlyResponse(data) {
      const years = data.year || [];
      const amounts = data.amount || [];
      const formattedAmounts = amounts.map((amount) =>
        parseFloat(amount).toFixed(2)
      );

      this.series = [
        {
          name: 'Amount',
          data: formattedAmounts
        }
      ];

      this.$refs.lineChart.updateOptions({
        xaxis: {
          categories: years
        }
      });
    },

    handleWeeklyResponse(data) {
      const daysOfWeek = data.dayOfWeek || [];
      const amounts = data.amount || [];
      const formattedAmounts = amounts.map((amount) =>
        parseFloat(amount).toFixed(2)
      );

      this.series = [
        {
          name: 'Amount',
          data: formattedAmounts
        }
      ];

      this.$refs.lineChart.updateOptions({
        xaxis: {
          categories: daysOfWeek
        }
      });
    },

    handleMonthlyResponse(data) {
      const months = data.month || [];
      const amounts = data.amount || [];
      const formattedAmounts = amounts.map((amount) =>
        parseFloat(amount).toFixed(2)
      );

      this.series = [
        {
          name: 'Amount',
          data: formattedAmounts
        }
      ];

      this.$refs.lineChart.updateOptions({
        xaxis: {
          categories: months
        }
      });
    }
  },

  mounted() {
    this.loadData();
  }
};
</script>

<style scoped>
  /* Add any scoped styles here if needed */
</style>
