<template>
  <div class="card" style="margin: 15px">
    <div class="card-content">
      <div class="columns">
        <div class="column is-four-fifths title is-6" style="font-weight: bold">
          <p class="title is-5">Overall Transaction count</p>
        </div>
        <div class="column">
          <b-dropdown
            @input="loadData"
            :disabled="isTableLoading"
            class="is-pulled-right"
            v-model="selectedFilter"
            aria-role="list"
          >
            <template #trigger>
              <b-button
                type="is-primary"
                size="is-small"
                icon-right="menu-down"
              >
                {{ selectedFilter.text }}
              </b-button>
            </template>

            <b-dropdown-item
              v-for="item in filters"
              :value="item"
              aria-role="listitem"
            >
              <span>{{ item.text }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <vue-apex-charts
        ref="lineChart"
        type="line"
        height="335"
        :options="chartOptions"
        :series="series"
      ></vue-apex-charts>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import NetworkManager from "@/network";

const today = new Date();

export default {
  name: "OverallTransactionsLineChart",
  components: { VueApexCharts },
  data() {
    return {
      dateRange: [today, today],
      isTableLoading: false,
      selectedFilter: { value: "WEEKLY", text: "Weekly" },
      filters: [
        { value: "YEARLY", text: "Yearly" },
        { value: "MONTHLY", text: "Monthly" },
        { value: "WEEKLY", text: "Weekly" },
      ],
      series: [],
      chartOptions: {
        chart: {
          height: 250,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        //   title: {
        //     text: 'Overall Transactions',
        //     align: 'left'
        //   },
        colors: [],
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          forceNiceScale: true,
          labels: {
            formatter: function (val) {
              return parseInt(val).toString();
            },
          },
        },
      },
    };
  },

  methods: {
    loadData: function () {
      const self = this;
      // const data ={
      //   range: self.selectedFilter.value,
      //   from_date: self.formatDate(self.dateRange[0]) + "T00:00:00",
      //   to_date: self.formatDate(self.dateRange[1]) + "T23:59:59",
      // }
      const data = {
        range: self.selectedFilter.value,
      };
      NetworkManager.apiRequest(
        "api/dashboard/AllTranCount",
        data,
        function (e) {
          console.log(e);

          if (e.statusCode === 200) {
            self.series = e.data.transactions;
            self.$refs.lineChart.updateOptions({
              xaxis: {
                categories: e.data.labels,
              },
            });
          } else {
            //
          }
        }
      );
    },
  },

  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
