<template>
    <div class="columns is-gapless" style="width: 95%;  margin: auto; margin-top: 15px;">
      <div class="column card mr-4 pr-2 pl-2">
        <div class="card-content">
          <div class="has-text-centered">
            <p style="margin-bottom: 6px">Success</p>
            <p class="title is-4" style="color: #008ffb; font-weight: 700">
              {{ tranData.completeTranCount }}
            </p>
          </div>
        </div>
      </div>

      <div class="column card mr-4 pr-2 pl-2">
        <div class="card-content">
          <div class="has-text-centered">
            <p style="margin-bottom: 6px">Pending</p>
            <p class="title is-4" style="color: #b89a02; font-weight: 700">
              {{ tranData.pendingTranCount }}
            </p>
          </div>
        </div>
      </div>

      <div class="column card mr-4 pr-2 pl-2">
        <div class="card-content">
          <div class="has-text-centered">
            <p style="margin-bottom: 6px">Failed</p>
            <p class="title is-4" style="color: #ff6470; font-weight: 700">
              {{ tranData.failedTranCount }}
            </p>
          </div>
        </div>
      </div>

      <div class="column card mr-4 pr-2 pl-2">
        <div class="card-content">
          <div class="has-text-centered">
            <p style="margin-bottom: 6px">Refunded</p>
            <p class="title is-4" style="color: #01779e; font-weight: 700">
              {{ tranData.refundTranCount }}
            </p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import NetworkManager from "@/network";

export default {
  name: "ECHannelingUserCountCards",
  data(){
    return {
      tranData: {
        completeTranCount: 0.0 ,
        pendingTranCount: 0.0 ,
        failedTranCount: 0.0 ,
        refundTranCount: 0.0 ,
      }
    }
  },

  methods: {
    loadData: function (){
      const self = this

      NetworkManager.apiRequest('api/dashboard/E-ChannellingCount', {}, function (e){
        if(e.statusCode === 200){
          self.tranData = e.data
          console.log(self.tranData);
        }
        else {
          //
        }
      })
    }
  },

  mounted() {
    this.loadData()
  }
}
</script>

<style scoped></style>