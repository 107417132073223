<template>
  <div>
    <div class="card" style="margin-left: 10px; margin-right: 10px">
      <div class="columns mt-2 pt-4" style="margin-left: 24px; margin-right:24px ; " >
        <div class="column is-half"  >
          <div
            class="card card-content"
            style="margin-left: 10px; margin-right: 10px; height: 90px"
          >
            <div class="columns is-align-center">
              <div class="column is-two-thirds">
              <p>
                <b>Total no of Remittance Transactions</b>
                <br />
                <span
                  style="margin-left: 18px; color: #0a341a; font-weight: bold ;font-size: 20px ; color: #0055A1;"
                  >{{ tranData.ceftTransactions }}</span
                >
              </p>
            </div>

            <div class="coloum" >
              <img
                src="../../../assets/Dashboard/TR.png"
                style="height: 30px; margin-top:20% ; margin-left:25%"
              />
            </div>
            </div>

          </div>
        </div>

        <div class="column is-half">
          <div
            class="card card-content"
            style="margin-left: 10px; margin-right: 10px; height: 90px"
          >
            <div class="columns">
              <div class="column is-two-thirds">
              <p>
                <b>Total Value of Transactions(LKR)</b>
                <br />
                <span
                  style="margin-left: 18px; color: #0a341a; font-weight: bold;font-size: 20px ; color: #0055A1;"
                  >{{ tranData.totalCeftValueLKR }}</span
                >
              </p>
            </div>

            <div class="coloum">
              <img
              src="../../../assets/Dashboard/TV.png"
                style="height: 40px; margin-top:20% ; margin-left:25% "
              />
            </div>
            </div>

          </div>
        </div>
      </div>

      <div class="section pt-0" >
        <div class="columns">
          <div class="column">
            <div class="card">
              <div class="card-content has-text-centered">
                <div style="height: 100px">
                  <img
                    style="height: 50px"
                    src="../../../assets/Dashboard/a.png"
                  />
                  <div style="font-weight: bold; font-size: 12px">
                    Total <br />Remittances
                  </div>
                  <div style="font-weight: bold; font-size: 18px ; color: #0a341a;">
                    {{ tranData.ceftTransactions }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="column">
            <div class="card">
              <div class="card-content has-text-centered">
                <div style="height: 100px">
                  <img
                    style="height: 50px"
                    src="../../../assets/Dashboard/b.png"
                  />
                  <div style="font-weight: bold; font-size: 12px">
                    Total <br />Bill Payments
                  </div>
                  <div style="font-weight: bold;  font-size: 18px ; color: #0a341a;">
                    {{ tranData.billTransactions }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="column">
            <div class="card">
              <div class="card-content has-text-centered">
                <div style="height: 100px">
                  <img
                    style="height: 50px"
                    src="../../../assets/Dashboard/c.png"
                  />
                  <div style="font-weight: bold; font-size: 12px">
                    Total <br />EChannelling
                  </div>
                  <div style="font-weight: bold; font-size: 18px ; color: #0a341a;">
                    {{ tranData.ecTransactions }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="column">
            <div class="card">
              <div class="card-content has-text-centered">
                <div style="height: 100px">
                  <img
                    style="height: 50px"
                    src="../../../assets/Dashboard/d.png"
                  />
                  <div style="font-weight: bold; font-size: 12px">
                    Total <br />Thyaga
                  </div>
                  <div style="font-weight: bold;font-size: 18px ; color: #0a341a;">
                    {{ tranData.thyagaTransactions }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="column">
            <div class="card">
              <div class="card-content has-text-centered">
                <div style="height: 100px">
                  <img
                    style="height: 50px"
                    src="../../../assets/Dashboard/e.png"
                  />
                  <div style="font-weight: bold; font-size: 12px">
                    Total <br />Users
                  </div>
                  <div style="font-weight: bold;font-size: 18px ; color: #0a341a;">
                    {{ tranData.appUsers }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NetworkManager from "@/network";

export default {
  name: "TransactionCounts",
  data() {
    return {
      tranData: {
        ceftTransactions: 0.0 ,
        billTransactions: 0.0 ,
        thyagaTransactions: 0.0 ,
        ecTransactions: 0.0 ,
        appUsers: 0.0 ,
        totalCeftValueLKR: 0.0 ,
      },
    };
  },

  methods: {
    loadData: function () {
      const self = this;

      NetworkManager.apiRequest(
        "api/dashboard/totalCountsAndValues",
        {},
        function (e) {
          if (e.statusCode === 200) {
            self.tranData = e.data;
            console.log(self.tranData);
          } else {
            //
          }
        }
      );
    },
  },

  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
