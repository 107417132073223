<!-- <template>
  <div class="card" style="margin: 10px">
    <div class="card-content">
      <div class="columns">
        <div class="column is-three-fifths" style="font-weight: bold; margin-left: 10px">
          <p class="title is-5">Remittance Transactions</p>
        </div>
        <div class="column">
          <b-dropdown position="is-right" @input="loadData" :disabled="isTableLoading" class="is-pulled-right"
            v-model="selectedFilter" aria-role="list">
            <template #trigger>
              <b-button type="is-primary" size="is-small" icon-right="menu-down">
                {{ selectedFilter.text }}
              </b-button>
            </template>

            <b-dropdown-item v-for="item in filters" :value="item" aria-role="listitem" style="min-width: content;">
              <span>{{ item.text }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="column is-narrow">
          <b-dropdown position="is-right" @change="loadData" :disabled="isTableLoading" class="is-pulled-right"
            v-model="selectedTran" aria-role="list">
            <template #trigger>
              <b-button type="is-primary" size="is-small" icon-right="menu-down">
                {{ selectedTran.text }}
              </b-button>
            </template>

            <b-dropdown-item v-for="item in Tranfilters" :value="item" aria-role="listitem">
              <span>{{ item.text }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <vue-apex-charts ref="barChart" type="bar" width="100%" height="200%" :options="chartOptions"
        :series="chartSeries"></vue-apex-charts>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import NetworkManager from "@/network";


export default {
  name: "HoldAppUsersBarChart",
  components: {
    VueApexCharts: VueApexCharts,
  },
  data() {
    return {
      chartSeries: [],
      selectedTran: { value: "COUNT", text: "No of Transactions" },
      Tranfilters: [
        { value: "COUNT", text: "No of Transactions" },
        { value: "VALUE", text: "Transactions Value" },
      ],
      selectedFilter: { value: "2024", text: "2024" },
      filters: [
        { value: "2024", text: "2024" },
        { value: "2023", text: "2023" },
        { value: "2022", text: "2022" },
        { value: "2021", text: "2021" },
        { value: "2020", text: "2020" },
      ],
      chartOptions: {
        colors:['#50C878','#FF033E','#FFD700','#87CEEB'],
        series: [],
        chart: {
          height: 350,
          type: 'bar',
          stacked: false,
        },
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '75%',
            endingShape: 'rounded'
          },
        },
        xaxis: {
          categories: [],
          zoom: true
        },
        yaxis: {
          title: {
            text: '',
          },
          labels: {
            formatter: function (val) {
              return parseInt(val).toString();
            }
          },
          min: 0,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== 'undefined') {
                return y.toFixed(0);
              }
              return y;
            },
          },
        },
      },
    };
  },

  methods: {
    loadData: function () {
      const self = this;

      setTimeout(() => {
        const data = {
          year: self.selectedFilter.value,
          transactionType: self.selectedTran.value
        };

        NetworkManager.apiRequest("api/dashboard/getTransactionTableData", data, function (e) {
          console.log(e);
          if (e.statusCode === 200) {
            // const netData = e.data.series.find(series => series.name === 'Net Data').data.filter(value => value !== 0);

            self.chartSeries = e.data.series.map((series) => {
              if (series.name === "Net Data") {
                // Filter out zeros from the "Net Data" series
                series.data = series.data.filter(value => value !== 0);
                return {
                  name: series.name,
                  type: 'area', // Assuming you want "Net Data" as an area chart
                  data: series.data,
                  fillOpacity: 1, // Set fill opacity to 1 to fill the area
                };
              } else {
                return {
                  name: series.name,
                  type: 'bar',
                  data: series.data,
                };
              }
            });

            // Extract the bankNames from the data property
            self.$refs.barChart.updateOptions({
              xaxis: {
                categories: e.data.monthNames,
              }
            });

          } else {
            // Handle error
          }
        });
      }, 500);
    },

  },

  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style> -->
<template>
  <div class="card" style="margin: 10px">
    <div class="card-content">
      <div class="columns">
        <div class="column is-three-fifths" style="font-weight: bold; margin-left: 10px">
          <p class="title is-5">Remittance Transactions</p>
        </div>
        <div class="column">
          <b-dropdown position="is-right" @input="loadData" :disabled="isTableLoading" class="is-pulled-right"
            v-model="selectedFilter" aria-role="list">
            <template #trigger>
              <b-button type="is-primary" size="is-small" icon-right="menu-down">
                {{ selectedFilter.text }}
              </b-button>
            </template>

            <b-dropdown-item v-for="item in filters" :value="item" :key="item.value" aria-role="listitem" style="min-width: content;">
              <span>{{ item.text }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="column is-narrow">
          <b-dropdown position="is-right" @change="loadData" :disabled="isTableLoading" class="is-pulled-right"
            v-model="selectedTran" aria-role="list">
            <template #trigger>
              <b-button type="is-primary" size="is-small" icon-right="menu-down">
                {{ selectedTran.text }}
              </b-button>
            </template>

            <b-dropdown-item v-for="item in Tranfilters" :value="item" :key="item.value" aria-role="listitem">
              <span>{{ item.text }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <vue-apex-charts ref="barChart" type="bar" width="100%" height="200%" :options="chartOptions"
        :series="chartSeries"></vue-apex-charts>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "HoldAppUsersBarChart",
  components: {
    VueApexCharts: VueApexCharts,
  },
  data() {
    return {
      chartSeries: [],
      selectedTran: { value: "COUNT", text: "No of Transactions" },
      Tranfilters: [
        { value: "COUNT", text: "No of Transactions" },
        { value: "VALUE", text: "Transactions Value" },
      ],
      selectedFilter: { value: "2024", text: "2024" },
      filters: [
        { value: "2024", text: "2024" },
        { value: "2023", text: "2023" },
        { value: "2022", text: "2022" },
        { value: "2021", text: "2021" },
        { value: "2020", text: "2020" },
      ],
      chartOptions: {
        colors: ['#50C878', '#FF033E', '#FFD700', '#87CEEB'],
        series: [],
        chart: {
          height: 350,
          type: 'bar',
          stacked: false,
        },
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '75%',
            endingShape: 'rounded'
          },
        },
        xaxis: {
          categories: [],
          zoom: true
        },
        yaxis: {
          title: {
            text: '',
          },
          labels: {
            formatter: function (val) {
              return parseInt(val).toString();
            }
          },
          min: 0,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== 'undefined') {
                return y.toFixed(0);
              }
              return y;
            },
          },
        },
      },
    };
  },

  mounted() {
    // Simulate API response by assigning dummy data
    this.chartSeries = [
      {
        name: "Success Data",
        type: "bar",
        data: [130, 50, 20, 40, 60, 80, 10, 120, 150, 100, 20, 22],
      },
      {
        name: "Hold Data",
        type: "bar",
        data: [100, 80, 0, 40, 20, 10, 3, 5, 2, 4, 6, 80],
      },
      {
        name: "Refund Data",
        type: "bar",
        data: [80, 30, 10, 50, 4, 6, 80, 70, 30, 20, 10, 50],
      },
      {
        name: "Net Data",
        type: "area",
        data: [20, 40, 10, 40, 100, 4, 5, 30, 70, 80, 90, 10],
        fillOpacity: 1,
      },
    ];

    // You may need to adjust x-axis categories based on your data
    this.chartOptions.xaxis.categories = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    // Update chart with dummy data
    this.$refs.barChart.updateSeries(this.chartSeries);
    this.$refs.barChart.updateOptions(this.chartOptions);
  },
};
</script>

<style scoped></style>
