<template>
  <div>
    <div class="card-content">
      <div class="columns">
        <div class="column is-three-fifths pl-0" style="font-weight: bold; margin-left: 10px">
          <p class="title is-5">Transactions</p>
        </div>
        <div class="column">
          <b-field>
            <b-datepicker class="is-pulled-right" position="is-right" @input="loadData" :disabled="isTableLoading" v-model="dateRange" :mobile-native="false" :max-date="new Date()"
              range>
              <template v-slot:trigger>
                <b-button icon-left="calendar-month-outline" size="is-small" />
              </template>
            </b-datepicker>
          </b-field>
        </div>
        <div class="column is-narrow">
          <b-dropdown position="is-right" @change="loadData" :disabled="isTableLoading" class="is-pulled-right" v-model="selectedFilter" aria-role="list">
            <template #trigger>
              <b-button type="is-primary" size="is-small" icon-right="menu-down">
                {{ selectedFilter.text }}
              </b-button>
            </template>

            <b-dropdown-item v-for="item in filters" :value="item" :key="item.value" aria-role="listitem">
              <span>{{ item.text }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div v-if="selectedFilter.value === 0" class="columns">
        <div class="column is-four-fifths"></div>
        <div class="column">
          <b-field>
            <b-input :value="`${dateRange[0].toLocaleDateString()} - ${dateRange[1].toLocaleDateString()}`" disabled></b-input>
          </b-field>
        </div>
      </div>
      <div class="card-content columns" style="padding-left: 0; padding-right: 0; padding-top: 0">
        <div class="column card mr-4 pr-2">
          <div class="card-content">
            <div class="has-text-centered">
              <p class="title is-5" style="margin-bottom: 6px">
                Number of Remittance Transactions
              </p>
              <p class="title is-2" :class="{
                'is-3':
                  numberWithCommas(
                    ceftTransactions.ceftTransactionCount
                  ).toString().length >= 12,
              }" style="color: #008ffb; font-weight: 700">
                {{ numberWithCommas(ceftTransactions.ceftTransactionCount) }}
              </p>
            </div>
            <hr />
            <div class="subtitle is-5" style="display: flex; justify-content: space-between">
              <div style="
                  display: flex;
                  flex-direction: column;
                  align-items: baseline;
                ">
                <p>Total Failed</p>
                <p>Total Refunded</p>
                <p>Total Hold</p>
              </div>
              <div style="display: flex; flex-direction: column">
                <p>{{ numberWithCommas(ceftTransactions.ceftFailedCount) }}</p>
                <p>
                  {{ numberWithCommas(ceftTransactions.ceftRefundedCount) }}
                </p>
                <p>{{ numberWithCommas(ceftTransactions.ceftHoldCount) }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="column card mr-4 pr-2">
          <div class="card-content">
            <div class="has-text-centered">
              <p class="title is-5" style="margin-bottom: 6px">
                Total Remittance Transaction Value (USD)
              </p>
              <p class="title is-2" :class="{
                'is-3':
                  numberWithCommas(
                    ceftTransactions.totalceftTransactions
                  ).toString().length >= 12,
              }" style="color: #008ffb; font-weight: 700">
                {{
                  numberWithCommas(
                    ceftTransactions.totalceftTransactions.toFixed(2)
                  )
                }}
              </p>
            </div>
            <hr />
            <p class="has-text-centered subtitle m-2 is-5">
              Sum of all Send Money Transaction values.
            </p>

            <div class="has-text-centered mt-auto">
              <a @click="totalTranValueModalActive = true" style="font-size: 16px; text-decoration: underline">View Chart</a>
            </div>
          </div>
        </div>
        <b-modal v-model="totalTranValueModalActive" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog" aria-label="Example Modal"
          close-button-aria-label="Close" aria-modal>
          <template>
            <div class="modal-card" style="width: auto">
              <header class="modal-card-head">
                <p class="modal-card-title">
                  Total Remittance Transaction Value (USD)
                </p>
                <button type="button" class="delete" @click="totalTranValueModalActive = false" />
              </header>
              <section class="modal-card-body">
                <total-transaction-value-growth-chart></total-transaction-value-growth-chart>
              </section>
            </div>
          </template>
        </b-modal>
        <div class="column card mr-4 pr-2 pl-2">
          <div class="card-content">
            <div class="has-text-centered">
              <p class="title is-5" style="margin-bottom: 6px">
                Number of Biller Transactions
              </p>
              <p class="title is-2" :class="{
                'is-3':
                  numberWithCommas(
                    billerTransactions.billTransactionCount
                  ).toString().length >= 12,
              }" style="color: #00e396; font-weight: 700">
                {{ numberWithCommas(billerTransactions.billTransactionCount) }}
              </p>
            </div>
            <hr />
            <div class="subtitle is-5" style="display: flex; justify-content: space-between">
              <div style="
                  display: flex;
                  flex-direction: column;
                  align-items: baseline;
                ">
                <p>Total Failed</p>
                <p>Total Refunded</p>
                <!-- <p>Total Hold</p> -->
              </div>
              <div style="display: flex; flex-direction: column">
                <p>{{ billerTransactions.billFailedCount }}</p>
                <p>{{ billerTransactions.billRefundedCount }}</p>
                <!-- <p>{{billerTransactions.ceftHoldCount}}</p> -->
              </div>
            </div>
          </div>
        </div>
        <div class="column card pr-2 pl-2">
          <div class="card-content">
            <div class="has-text-centered">
              <p class="title is-5" style="margin-bottom: 6px">
                Total Biller Transaction Value (USD)
              </p>
              <p class="title is-2" :class="{
                'is-3':
                  numberWithCommas(
                    billerTransactions.totalbillTransactions
                  ).toString().length >= 12,
              }" style="color: #00e396; font-weight: 700">
                {{
                  numberWithCommas(
                    billerTransactions.totalbillTransactions.toFixed(2)
                  )
                }}
              </p>
            </div>
            <hr />
            <p class="has-text-centered subtitle m-2 is-5">
              Sum of all Biller Transaction values.
            </p>

            <div class="has-text-centered mt-auto">
              <a @click="totalTranValueBillerModalActive = true" style="font-size: 16px; text-decoration: underline">View Chart</a>
            </div>
          </div>
        </div>

        <b-modal v-model="totalTranValueBillerModalActive" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog" aria-label="Example Modal"
          close-button-aria-label="Close" aria-modal>
          <template>
            <div class="modal-card" style="width: auto">
              <header class="modal-card-head">
                <p class="modal-card-title">
                  Total Biller Transaction Value (USD)
                </p>
                <button type="button" class="delete" @click="totalTranValueBillerModalActive = false" />
              </header>
              <section class="modal-card-body">
                <TotalTransactionValueBillerGrowthChart></TotalTransactionValueBillerGrowthChart>
              </section>
            </div>
          </template>
        </b-modal>
      </div>
      <div class="card-content columns" style="padding-left: 0; padding-right: 0">
        <div class="column card mr-4 pr-2 pl-2">
          <div class="card-content">
            <div class="has-text-centered">
              <p class="title is-5" style="margin-bottom: 6px">
                Number of Thyaga Transactions
              </p>
              <p class="title is-2" :class="{
                'is-3':
                  numberWithCommas(
                    thyagaTransactions.thyagaTransactionCount
                  ).toString().length >= 12,
              }" style="color: #feb019; font-weight: 700">
                {{
                  numberWithCommas(thyagaTransactions.thyagaTransactionCount)
                }}
              </p>
            </div>
            <hr />
            <div class="subtitle is-5" style="display: flex; justify-content: space-between">
              <div style="
                  display: flex;
                  flex-direction: column;
                  align-items: baseline;
                ">
                <p>Total Failed</p>
                <p>Total Refunded</p>
                <!-- <p>Total Hold</p> -->
              </div>
              <div style="display: flex; flex-direction: column">
                <p>{{ thyagaTransactions.thyagaFailedCount }}</p>
                <p>{{ thyagaTransactions.thyagaRefundedCount }}</p>
                <!-- <p>{{thyagaTransactions.ceftHoldCount}}</p> -->
              </div>
            </div>
          </div>
        </div>
        <div class="column card mr-4 pr-2 pl-2">
          <div class="card-content">
            <div class="has-text-centered">
              <p class="title is-5" style="margin-bottom: 6px">
                Total Thyaga Transaction Value (USD)
              </p>
              <p class="title is-2" :class="{
                'is-3':
                  numberWithCommas(
                    thyagaTransactions.totalThyagaTransactions
                  ).toString().length >= 12,
              }" style="color: #feb019; font-weight: 700">
                {{
                  numberWithCommas(
                    thyagaTransactions.totalThyagaTransactions.toFixed(2)
                  )
                }}
              </p>
            </div>
            <hr />
            <p class="has-text-centered subtitle m-2 is-5">
              Sum of all Thayaga Transaction values.
            </p>

            <div class="has-text-centered mt-auto">
              <a @click="TotalTransactionValueThaygaModalActive = true" style="font-size: 16px; text-decoration: underline">View Chart</a>
            </div>
          </div>
        </div>

        <b-modal v-model="TotalTransactionValueThaygaModalActive" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog" aria-label="Example Modal"
          close-button-aria-label="Close" aria-modal>
          <template>
            <div class="modal-card" style="width: auto">
              <header class="modal-card-head">
                <p class="modal-card-title">
                  Total Thyaga Transaction Value (USD)
                </p>
                <button type="button" class="delete" @click="TotalTransactionValueThaygaModalActive = false" />
              </header>
              <section class="modal-card-body">
                <TotalTransactionValueThaygaGrowthChart></TotalTransactionValueThaygaGrowthChart>
              </section>
            </div>
          </template>
        </b-modal>

        <div class="column card mr-4 pr-2 pl-2">
          <div class="card-content">
            <div class="has-text-centered">
              <p class="title is-5" style="margin-bottom: 6px">
                Number of E-Channelling Transactions
              </p>
              <p class="title is-2" :class="{
                'is-3':
                  numberWithCommas(
                    echannelingTransactions.echannelingTransactionCount
                  ).toString().length >= 12,
              }" style="color: #ff4560; font-weight: 700">
                {{
                  numberWithCommas(
                    echannelingTransactions.echannelingTransactionCount
                  )
                }}
              </p>
            </div>
            <hr />
            <div class="subtitle is-5" style="display: flex; justify-content: space-between">
              <div style="
                  display: flex;
                  flex-direction: column;
                  align-items: baseline;
                ">
                <p>Total Failed</p>
                <p>Total Refunded</p>
                <!-- <p>Total Hold</p> -->
              </div>
              <div style="display: flex; flex-direction: column">
                <p>{{ echannelingTransactions.echannelingFailedCount }}</p>
                <p>{{ echannelingTransactions.echannelingRefundedCount }}</p>
                <!-- <p>{{thyagaTransactions.ceftHoldCount}}</p> -->
              </div>
            </div>
          </div>
        </div>
        <div class="column card pr-2 pl-2">
          <div class="card-content">
            <div class="has-text-centered">
              <p class="title is-5" style="margin-bottom: 6px">
                Total E-Channelling Transaction Value (USD)
              </p>
              <p class="title is-2" :class="{
                'is-3':
                  numberWithCommas(
                    echannelingTransactions.totalechannelingTransactions
                  ).toString().length >= 12,
              }" style="color: #ff4560; font-weight: 700">
                {{
                  numberWithCommas(
                    echannelingTransactions.totalechannelingTransactions.toFixed(
                      2
                    )
                  )
                }}
              </p>
            </div>
            <hr />
            <p class="has-text-centered subtitle is-5 m-2">
              Sum of all E-Channelling Transaction values.
            </p>

            <div class="has-text-centered mt-auto">
              <a @click="TotalTransactionValueEchannelingModalActive = true" style="font-size: 16px; text-decoration: underline">View Chart</a>
            </div>
          </div>
        </div>
        <b-modal v-model="TotalTransactionValueEchannelingModalActive" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog" aria-label="Example Modal"
          close-button-aria-label="Close" aria-modal>
          <template>
            <div class="modal-card" style="width: auto">
              <header class="modal-card-head">
                <p class="modal-card-title">
                  Total E-Channelling Transaction Value (USD)
                </p>
                <button type="button" class="delete" @click="TotalTransactionValueEchannelingModalActive = false" />
              </header>
              <section class="modal-card-body">
                <TotalTransactionValueEchannelingGrowthChart></TotalTransactionValueEchannelingGrowthChart>
              </section>
            </div>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import NetworkManager from "@/network";
import TotalTransactionValueGrowthChart from "./TotalTransactionValueGrowthChart";
import TotalTransactionValueBillerGrowthChart from "./TotalTransactionValueBillerGrowthChart";
import TotalTransactionValueThaygaGrowthChart from "./TotalTransactionValueThaygaGrowthChart";
import TotalTransactionValueEchannelingGrowthChart from "./TotalTransactionValueEchannelingGrowthChart";

const today = new Date();

export default {
  name: "AllTransactionsTable",
  components: {
    TotalTransactionValueGrowthChart,
    TotalTransactionValueBillerGrowthChart,
    TotalTransactionValueThaygaGrowthChart,
    TotalTransactionValueEchannelingGrowthChart,
  },
  data() {
    return {
      dateRange: [today, today],
      selectedFilter: { value: 7, text: "This Week" },
      filters: [
        { value: 7, text: "This Week" },
        { value: 14, text: "Last Two Weeks" },
        { value: 30, text: "Last Month" },
        { value: 90, text: "Last Three Months" },
        { value: 365, text: "Last Year" },
        // { value: 0, text: "Custom" },
      ],
      totalTranValueModalActive: false,
      totalTranValueBillerModalActive: false,
      TotalTransactionValueThaygaModalActive: false,
      TotalTransactionValueEchannelingModalActive: false,

      ceftTransactions: {
        totalceftTransactions: 0,
        ceftTransactionCount: 0,
        ceftFailedCount: 0,
        ceftRefundedCount: 0,
        ceftHoldCount: 0,
      },
      billerTransactions: {
        totalbillTransactions: 0,
        billTransactionCount: 0,
        billFailedCount: 0,
        billRefundedCount: 0,
      },

      thyagaTransactions: {
        totalThyagaTransactions: 0,
        thyagaTransactionCount: 0,
        thyagaFailedCount: 0,
        thyagaRefundedCount: 0,
      },

      echannelingTransactions: {
        totalechannelingTransactions: 0,
        echannelingTransactionCount: 0,
        echannelingFailedCount: 0,
        echannelingRefundedCount: 0,
      },
    };
  },

  watch: {
    dateRange(newVal, oldVal) {
      this.selectedFilter = { value: 0, text: "Custom" };
    },
  },

  methods: {
    // numberWithCommas: function (x) {
    //   // Ensure x is a number and round it to 2 decimal places
    //   let roundedNumber = parseFloat(x).toFixed(2);

    //   // Split the number into integer and decimal parts
    //   let y = roundedNumber.toString().split(".");

    //   // Add thousand separators to the integer part
    //   let z = y[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    //   // Concatenate the integer part with the decimal part and return
    //   return z + "." + y[1];
    // },
    formatDate: function (date) {
      let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    loadData: function () {
      const self = this;

      self.isTableLoading = true;

      setTimeout(() => {
        const data = {
          range: self.selectedFilter.value,
          fromDate: self.formatDate(self.dateRange[0]),
          toDate: self.formatDate(self.dateRange[1]),
        };

        NetworkManager.apiRequest(
          "api/dashboard/getTransactionValues",
          data,
          function (e) {
            console.log(e);
            if (e.statusCode === 200) {
              self.ceftTransactions = e.data.ceft;
              self.billerTransactions = e.data.biller;
              self.thyagaTransactions = e.data.thyaga;
              self.echannelingTransactions = e.data.echanneling;
            } else {
              //
            }
            self.isTableLoading = false;
          }
        );
      }, 200);
    },
  },

  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
