<template>
  <section>
    <div class="card-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb
              align="is-left"
          >
            <b-breadcrumb-item tag='router-link' to="" active >
              IPG Provider Currencies
            </b-breadcrumb-item>

            <b-tooltip v-if="provider.locked" label="Locked" position="is-right" type="is-dark">
              <b-icon
                  icon="lock"
                  size="is-medium">
              </b-icon>
            </b-tooltip>
            <b-tooltip v-if="!hasRatesUpdated" label="Please update rates" position="is-right" type="is-dark">
              <b-icon
                  icon="alert-circle"
                  type="is-danger"
                  size="is-medium">
              </b-icon>
            </b-tooltip>
          </b-breadcrumb>
        </div>
        <div class="column">
        </div>
      </div>
    </div>
    <div class="card-content" style="overflow: auto; height: 70vh">

      <change-request v-if="hasPermission('ipg-currency-approve')" :data="crData" :filter-out="filterOutFromCR" :is-loading="crIsLoading" custom-change-request="providerCurrency" @approve-or-reject="approve_or_reject" />

      <section class="columns">
        <b-field class="column is-one-quarter" grouped>
          <template slot="label">Provider Bank Code </template>
          <b-field>
            <b-input :value="provider.provider_bank_code" readonly></b-input>
          </b-field>
        </b-field>
        <b-field class="column is-one-quarter" grouped>
          <template slot="label">Provider Bank Name </template>
          <b-field>
            <b-input :value="provider.provider_bank_name" readonly></b-input>
          </b-field>
        </b-field>
      </section>

      <section style="margin-top: 20px">
        <div class="card">
          <div class="card-content">
            <div style="margin-bottom: 20px">Provider Currencies</div>
            <div v-for="currency in providerCurrencies">
              <div class="columns">
                <div class="column is-one-fifth">
                  <b-field>
                    <b-tag type="is-primary is-light" size="is-medium">{{currency.currency_code}}</b-tag>
                  </b-field>
                </div>
                <div class="column">
                  <currency-input
                      label="Transaction Rate"
                      v-model="currency.transaction_rate.value"
                      :max-length="10"
                      :decimals="4"
                      :label-position="labelPosition"
                      :type="currency.transaction_rate.class"
                      :message="currency.transaction_rate.message"
                      :disabled="!editEnabled"
                      @on-blur="currency.transaction_rate.blur()"
                  ></currency-input>
                </div>
                <div class="column">
                  <currency-input
                      label="Settlement Rate"
                      v-model="currency.settlement_rate.value"
                      :max-length="10"
                      :decimals="4"
                      :label-position="labelPosition"
                      :type="currency.settlement_rate.class"
                      :message="currency.settlement_rate.message"
                      :disabled="!editEnabled"
                      @on-blur="currency.settlement_rate.blur()"
                  ></currency-input>
                </div>
                <div class="column is-one-fifth">
                  <b-field>
                    <b-switch v-model="currency.is_active"
                              type="is-success" :disabled="!editEnabled">
                      {{ currency.is_active ? 'Active' : 'Inactive' }}
                    </b-switch>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <footer class="card-footer">
      <div class="columns" style="width: 100%">
        <div class="column">
        </div>
        <div class="column">
          <div v-if="provider.locked !== 1 && hasPermission('ipg-currency-edit')" class="buttons is-pulled-right" style="margin: 5px">
            <b-button v-if="editEnabled" @click="validateAndSubmit()" :loading="false" type="is-success">Save</b-button>
            <b-button v-if="!editEnabled" @click="editEnabled = true"  type="is-danger">Enable Edit</b-button>
            <b-button v-if="editEnabled" @click="editEnabled = false"  type="is-danger">Disable Edit</b-button>
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
import ChangeRequest from "@/components/ChangeRequest"
import NetworkManager from "@/network"
import CurrencyInput from "@/components/CurrencyInput"
import {ProviderCurrency} from "@/views/IPGProviderCurrency/Support"

export default {
  name: 'IPGProviderCurrencyList',
  components: {
    CurrencyInput,
    ChangeRequest
  },
  data() {
    return {
      labelPosition: 'on-border',
      editEnabled: false,
      hasRatesUpdated: true,

      crData: [],
      crIsLoading: false,
      filterOutFromCR: ['id', 'created_at', 'updated_at', 'locked'],

      minValue: 0.0001,
      maxValue: 9999999999,

      provider: {
        provider_bank_code: '',
        provider_bank_name: ''
      },

      providerCurrencies: [],
      provider_bank_code: '',
    }
  },

  computed: {

  },

  watch: {

  },

  methods: {
    loadData: function (){
      const  self = this

      self.providerCurrencies = []
      if(!self.isSuperAdmin()) {
        self.provider_bank_code = self.authUser().branch
      }
      else {
        self.provider_bank_code = self.$route.params.id
      }

      const data = {
        provider_bank_code: self.provider_bank_code
      }

      self.showFullScreenLoader()

      NetworkManager.apiRequest('api/IPGProvider/currency/list', data, function (e){
        if(e.statusCode === 200){
          e.data.currencies.forEach(function (item){
            let currency = new ProviderCurrency()
            currency.id = item.id
            currency.currency_code = item.currency_code
            currency.transaction_rate.value = item.transaction_rate
            currency.settlement_rate.value = item.settlement_rate
            currency.is_active = item.disabled === 0

            self.providerCurrencies.push(currency)
          })

          self.provider = e.data.provider
          self.crData = e.data.changeRequests
          self.hasRatesUpdated = e.data.hasRatesUpdated

          if(!self.hasRatesUpdated){
            self.$buefy.dialog.alert({
              title: 'Acknowledgement',
              message: "Today's <b>Currency Rates</b> has not been updated yet!.",
              type: 'is-danger',
              hasIcon: true
            })
          }
        }

        self.hideFullScreenLoader()
      })
    },

    validate: function (){
      const self = this
      let isValid = true

      self.providerCurrencies.forEach(function (item){
        isValid = item.transaction_rate.validate()
        isValid = item.settlement_rate.validate()

        if(isValid){
          if((item.transaction_rate.value < self.minValue || item.transaction_rate.value > self.maxValue)){
            isValid = false
            item.transaction_rate.invalidate('value must be greater than ' + self.minValue + ' and less than ' + self.maxValue)
          }

          if((item.settlement_rate.value < 0 || item.settlement_rate.value > self.maxValue)){
            isValid = false
            item.transaction_rate.invalidate('value must be greater than 0.0 and less than ' + self.maxValue)
          }
        }

      })

      return isValid
    },

    validateAndSubmit: function (){
      const  self = this

      if(self.validate()){
        let list = []

        self.providerCurrencies.forEach(function (item){
          let data = {
            disabled: item.is_active ? 0 : 1,
            provider_bank_code: item.provider_bank_code,
            currency_code: item.currency_code,
            transaction_rate: item.transaction_rate.value,
            settlement_rate: item.settlement_rate.value
          }

          list.push(data)
        })

        let data = {
          provider_bank_code: self.provider_bank_code,
          currencies: list
        }

        self.showFullScreenLoader()

        NetworkManager.apiRequest('api/IPGProvider/currency/save', data, function (e){
          if(e.statusCode === 200){
            const params = {
              type: 'is-success',
              message: 'IPG provider currencies saved'
            }
            self.$buefy.toast.open(params)
            self.editEnabled = false
            self.loadData()
          }
          else {
            const params = {
              type: 'is-warning',
              message: 'IPG provider currencies save Failed!.'
            }
            self.$buefy.toast.open(params)
          }

          self.hideFullScreenLoader()
        })
      }

    },

    approve_or_reject: function (data){
      const self = this
      self.crIsLoading = true

      NetworkManager.apiRequest('api/IPGProvider/currency/approve', data, function (e){
        if(e.statusCode === 200){
          const params = {
            type: 'is-success',
            message: 'IPG Provider Currency ' + (data.actionTaken === 'approve' ? 'approved' : 'rejected') + ' successfully'
          }
          self.$buefy.toast.open(params)
        }
        else {
          const params = {
            type: 'is-warning',
            message: 'IPG Provider Currency ' + (data.actionTaken === 'approve' ? 'approved' : 'rejected') + ' failed!'
          }
          self.$buefy.toast.open(params)
        }

        location.reload()
        self.crIsLoading = false
      })
    }

  },

  mounted() {
    const self = this
    self.loadData()
  }
};
</script>

<style>
.is-sticky-column-one {
  background: #23d160 !important;
  color: white !important;
}
.is-sticky-column-two {
  background: #167df0 !important;
  color: white !important;
}
</style>
