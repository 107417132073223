<template>
  <div class="block">
    <div class="scrollable-container" style="height: 100%; height: 100%; overflow-y: auto;">
      <div class="container" style="height: 88vh; width: 80vw;">
        <div class="columns" style="margin-left: 30px; margin-top: 20px;">
          <div class="column">
            <total-count />
          </div>
          
          <div class="column">
            <total-active-count />
          </div>
        </div>

        <div class="columns" style="margin-left: 10px;">
          <div class="column">
          <transaction-table-mixed-chart></transaction-table-mixed-chart>
        </div>
        </div> 
        <div class="columns" style="margin-left: 10px;">
          <div class="column">
            <transactions-cards></transactions-cards>
          </div>
      </div>
      <div class="columns" style="margin-left: 10px;">
        <div class="column">
          <app-users></app-users>
        </div>
        <div class="column">
          <top-billers></top-billers>
        </div>
      </div>
      <div class="columns" style="margin-left: 10px;">
        <div class="column">
          <overall-transactions-line-chart></overall-transactions-line-chart>
        </div>
        <div class="column">
          <country-wise-user-count-bar-chart></country-wise-user-count-bar-chart>
        </div>
      </div>
      <div class="columns">
        
      </div>
    </div>
    </div>
  </div>
</template>
  
<script>
// import NumericPieChart from "@/views/Dashboard/Components/NumericPieChart"
import TotalActiveCount from "@/views/AuditDashboard/Components/TotalActiveCount";
import TotalCount from "@/views/AuditDashboard/Components/TotalCount";
import TransactionsCards from "@/views/AuditDashboard/Components/TransactionsCards";
import AppUsers from "@/views/AuditDashboard/Components/AppUsers";
import TopBillers from "@/views/AuditDashboard/Components/TopBillers";
import OverallTransactionsLineChart from "@/views/AuditDashboard/Components/OverallTransactionsLineChart";
import CountryWiseUserCountBarChart from "@/views/AuditDashboard/Components/CountryWiseUserCountBarChart";
import TransactionTableMixedChart from "@/views/AuditDashboard/Components/TransactionTableMixedChart";
// import AllTransactionsBarChart from "@/views/AuditDashboard/Components/AllTransactionsBarChart";
// import AppUsersPieChart from "@/views/Dashboard/Components/AppUsersPieChart";
// import CurrencyTransactionsBarChart from "@/views/Dashboard/Components/CurrencyTransactionsBarChart";
// import TransactionPercentagePieChart from "@/views/Dashboard/Components/TransactionPercentagePieChart";
// import CeftTransactionsAmountFilter from "@/views/AuditDashboard/Components/CeftTransactionsAmountFilter";
// import HoldUserList from "@/views/AuditDashboard/Components/HoldUserList";
// import UnholdUserList from "@/views/AuditDashboard/Components/UnholdUserList";
// import HoldTransactionList from "@/views/AuditDashboard/Components/HoldTransactionList"
// import SendMoneyTransactionsLineChart from "@/views/AuditDashboard/Components/SendMoneyTransactionsLineChart";
// import AuditAppUsersPieChart from "@/views/AuditDashboard/Components/AppUsersPieChart.vue";
// import BeneficiaryCountBarChart from "@/views/AuditDashboard/Components/BeneficiaryCountBarChart.vue";
// import HoldTransactionsBarChart from "@/views/AuditDashboard/Components/HoldTransactionsBarChart.vue";
// import HoldAppUsersBarChart from "@/views/AuditDashboard/Components/HoldAppUsersBarChart.vue";

export default {
  name: "AuditDashboard",
  components: {
    // CurrencyTransactionsBarChart,
    // NumericPieChart,
    TotalCount,
    TotalActiveCount,
    TransactionsCards,
    AppUsers,
    TopBillers,
    OverallTransactionsLineChart,
    CountryWiseUserCountBarChart,
    TransactionTableMixedChart
    // AllTransactionsBarChart,
    // AppUsersPieChart,
    // TransactionPercentagePieChart,
    // CeftTransactionsAmountFilter,
    // HoldUserList,
    // UnholdUserList ,
    // HoldTransactionList,
    // SendMoneyTransactionsLineChart,
    // AuditAppUsersPieChart,
    // BeneficiaryCountBarChart,
    // HoldTransactionsBarChart,
    // HoldAppUsersBarChart
  },
  data() {
    return {
      transactionCounts: {
        data: [],
        labels: []
      },

      transactionValues: {
        data: [],
        labels: []
      }
    }
  },

  methods: {
    // loadCountChartData: function (e){
    //   const self = this
    //
    //   let data = {
    //     range: e
    //   }
    //
    //   NetworkManager.apiRequest('api/dashboard/tranCount', data, function (e){
    //     if(e.statusCode === 200){
    //       self.transactionCounts.data = e.data.tranCounts
    //       self.transactionCounts.labels = e.data.labels
    //     }
    //     else {
    //       //
    //     }
    //   })
    // },
    //
    // loadValueChartData: function (e){
    //   const self = this
    //
    //   let data = {
    //     range: e
    //   }
    //
    //   NetworkManager.apiRequest('api/dashboard/tranValue', data, function (e){
    //     if(e.statusCode === 200){
    //       self.transactionValues.data = e.data.tranValues
    //       self.transactionValues.labels = e.data.labels
    //     }
    //     else {
    //       //
    //     }
    //   })
    // }
  },

  mounted() {
    // this.loadValueChartData(7)
    // this.loadCountChartData(7)
  }
}
</script>
  
<!-- <style scoped>
.custom-tabs .tabs {
  width: 200px; /* Set the desired width */
}
</style> -->