<template>
  <div class="card" style="margin: 10px">
    <div class="card-content">
      <vue-apex-charts ref="barChart" type="bar" width="90%" height="350" :options="chartOptions"
        :series="chartSeries"></vue-apex-charts>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import NetworkManager from "@/network";

export default {
  name: "CurrencyTransactionsBarChart",
  components: {
    VueApexCharts: VueApexCharts,
  },
  data() {
    return {
      chartSeries: [],
      chartOptions: {
        chart: {
          height: 500,
          type: "bar",
        },
        plotOptions: {
          bar: {
            columnWidth: '90%',
          },
        },
        dataLabels: {
          enabled: false, // Disable data labels
        },
      xaxis: {
        categories: [],
      },
      title: {
        text: "Overall Transactions",
      },
    },
    };
},

methods: {
  loadData: function () {
    const self = this;

    NetworkManager.apiRequest(
      "api/dashboard/getTotalTransactionsByMonth",
      {},
      function (e) {
        if (e.statusCode === 200) {
          self.chartSeries = e.data.transactions.map((transactions) => ({
            name: transactions.name,
            data: transactions.data,
          }));

          self.$refs.barChart.updateOptions({
            xaxis: {
              categories: e.data.monthNames,
            }
          })
        }
        else {
          //
        }
      }
    );
  },
},

mounted() {
  this.loadData();
},
};
</script>

<style scoped></style>
