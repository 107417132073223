<template>
  <div class="card" style="margin: 10px">
    <div class="card-content">
      <div class="columns">
        <div class="column is-four-fifths title is-6" style="font-weight: bold">
          <p class="title is-5">Country-Wise User Count</p>
        </div>
        <div class="column">
          <b-field>
            <b-datepicker class="is-pulled-right" position="is-right" @input="loadData" :disabled="isTableLoading"
              v-model="dateRange" :max-date="new Date()" :mobile-native="false" range>
              <template v-slot:trigger>
                <b-button icon-left="calendar-month-outline" size="is-small" />
              </template>
            </b-datepicker>
          </b-field>
        </div>
        <div class="column is-narrow">
          <b-dropdown position="is-right" @change="loadData" :disabled="isTableLoading" class="is-pulled-right"
            v-model="selectedFilter" aria-role="list">
            <template #trigger>
              <b-button type="is-primary" size="is-small" icon-right="menu-down">
                {{ selectedFilter.text }}
              </b-button>
            </template>

            <b-dropdown-item v-for="item in timeFilters" :value="item" aria-role="listitem">
              <span>{{ item.text }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div v-if="selectedFilter.value === 0" class="columns">
        <div class="column is-three-fifths"></div>
        <div class="column">
          <b-field>
            <b-input :value="`${dateRange[0].toLocaleDateString()} - ${dateRange[1].toLocaleDateString()}`"
              disabled></b-input>
          </b-field>
        </div>
      </div>
      <div class="card-content">
        <vue-apex-charts ref="barChart" type="bar" :options="chartOptions" :series="series"></vue-apex-charts>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import NetworkManager from "@/network";

const today = new Date();

export default {
  name: "CountryWiseUserCountBarChart",
  components: {
    VueApexCharts: VueApexCharts,
  },
  data() {
    return {
      dateRange: [today, today],
      selectedFilter: { value: 7, text: "This Week" },
      isTableLoading: false,
      timeFilters: [
        { value: 7, text: "This Week" },
        { value: 14, text: "Last Two Weeks" },
        { value: 30, text: "Last Month" },
        { value: 90, text: "Last Three Months" },
        { value: 365, text: "Last Year" },
        // { value: 0, text: 'Custom' },
      ],
      series: [
        {
          name: "Counts",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          width: 480,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        colors: [
          "#1f77b4", // Blue
          "#ff7f0e", // Orange
          "#2ca02c", // Green
          "#d62728", // Red
          "#9467bd", // Purple
          "#8c564b", // Brown
          "#e377c2", // Pink
          "#7f7f7f", // Gray
          "#bcbd22", // Olive
          "#17becf", // Teal
          "#1f1f7c", // Navy
          "#7c1f1f", // Maroon
          "#1f7c1f", // Forest Green
          "#7c1f7c", // Indigo
          "#7c7c1f", // Olive Green
          "#1f7c7c", // Turquoise
          "#4b8c1f", // Moss Green
          "#ff8c8c", // Coral
          "#8c1f1f", // Dark Red
          "#ffbf00", // Gold
          "#007f7f", // Dark Cyan
          "#ff4d94", // Hot Pink
          "#66b2b2", // Steel Blue
          "#4dff4d", // Lime Green
          "#ff00ff", // Magenta
        ],
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true, // This property ensures each bar will have a different color
          },
        },
        xaxis: {
          categories: [],
          title: {
            text: "Country",
          },
        },
        yaxis: {
          forceNiceScale: true,
          title: {
            text: "User Counts",
          },
          labels: {
            formatter: function (val) {
              return parseInt(val).toString();
            },
          },
        },
        dataLabels: {
          enabled: false, // set this to false to remove the data labels
        },
      },
    };
  },

  watch: {
    dateRange(newVal, oldVal) {
      this.selectedFilter = { value: 0, text: "Custom" };
    },
  },

  methods: {
    formatDate: function (date) {
      let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    loadData: function () {
      const self = this;

      setTimeout(() => {
        const data = {
          range: self.selectedFilter.value,
          fromDate: self.formatDate(self.dateRange[0]) + "T00:00:00",
          toDate: self.formatDate(self.dateRange[1]) + "T23:59:59",
        }
        NetworkManager.apiRequest('api/dashboard/CountryWiseAppUserCount', data, function (e) {
          console.log(e);
          if (e.statusCode === 200) {
            self.series = [{
              name: 'App Users',
              data: e.data.counts
            }]
            self.$refs.barChart.updateOptions({
              xaxis: {
                categories: e.data.countries.map((country) => {
                  // Check if the country name contains a comma
                  const indexOfComma = country.indexOf(',');

                  // If a comma is found, take the part before the comma; otherwise, use the original string
                  const formattedCountry = indexOfComma !== -1 ? country.substring(0, indexOfComma).trim() : country.trim();
                  return formattedCountry;
                })
              }
            })
          }
          else {
            //
          }
        })
      }, 500)


    }
  },

  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
