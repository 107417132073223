<template>
    <div class="block">
    <div style=" overflow-y: auto; overflow-x: hidden">
      <div class="container" style="height: 78vh; width: 75vw;">
            <TransactionCounts></TransactionCounts>  
        <div class="columns">
          <div class="column s-half">
            <AllTransactions></AllTransactions>
          </div>
          <div class="column " style="width:500px">
            <TransactionsChart></TransactionsChart>
          </div>
        </div>

        <div class="columns">
          <div class="column is-one-fifths">
            <AppUsersChart></AppUsersChart>
          </div>
          <div class="column">
            <AppRegistationLineChart></AppRegistationLineChart>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <OverallTransactionsBarChart></OverallTransactionsBarChart>
          </div>
          <div class="column">
            <CurrencyTransactionsLineChart></CurrencyTransactionsLineChart>
          </div>
        </div>
      </div>
    </div>
  </div>
    
</template>
<script>
import AllTransactions from "@/views/Dashboard/Components/AllTransactions";
import TransactionsChart from "@/views/Dashboard/Components/TransactionsChart";
import AppUsersChart from "@/views/Dashboard/Components/AppUsersChart";
import CurrencyTransactionsLineChart from "@/views/Dashboard/Components/CurrencyTransactionsLineChart";
import OverallTransactionsBarChart from "@/views/Dashboard/Components/OverallTransactionsBarChart";
import TransactionCounts from "@/views/Dashboard/Components/TransactionCounts";
import AppRegistationLineChart from "./Components/AppRegistationLineChart.vue";

export default{
    name: "DashboardApay",
    components:{
        AllTransactions,
        TransactionsChart,
        CurrencyTransactionsLineChart,
        AppUsersChart,
        OverallTransactionsBarChart,
        TransactionCounts,
        AppRegistationLineChart
    }
}
</script>
<style></style>