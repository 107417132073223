<template>
    <div>
      <div class="card columns">
        <b-tooltip class="column" type="is-light" position="is-bottom" multilined>
        <div class="column has-text-centered">
          <p class="title is-4" style="margin-bottom: 6px; ">Total App User <br> Count</p>
          <p class="title is-3" style="color: #008ffb; font-weight: 700;">{{ numberWithCommas(totalCounts.appUsersValues.totalAppUserCount) }}</p>
        </div>
        <template v-slot:content>
                  <div class="m-4 subtitle is-6" style="display: flex; justify-content: space-between;">
                    <div style="display: flex; flex-direction: column; align-items: baseline;">
                        <p><b>Today</b></p>
                        <p><b>This Week</b></p>
                        <p><b>This Month</b></p>
                        <p><b>This Year</b></p>
                    </div>
                    <div style="display: flex; flex-direction: column;">
                        <p><b>{{ numberWithCommas(totalCounts.appUsersValues.todayAppUserCount)}}</b></p>
                        <p><b>{{ numberWithCommas(totalCounts.appUsersValues.weekAppUserCount) }}</b></p>
                        <p><b>{{ numberWithCommas(totalCounts.appUsersValues.monthAppUserCount) }}</b></p>
                        <p><b>{{ numberWithCommas(totalCounts.appUsersValues.yearAppUserCount) }}</b></p>
                    </div>
                  </div>
              </template>
        </b-tooltip>
        <div class="with-vertical-line"></div>
        <b-tooltip class="column" type="is-primary is-light" position="is-bottom" multilined>
        <div class="column has-text-centered">
          <p class="title is-4" style="margin-bottom: 6px; ">Total Beneficiary <br> Count</p>
          <p class="title is-3" style="color: #008ffb; font-weight: 700;">{{ numberWithCommas(totalCounts.beneficiariesValues.totalBeneficiariesCount) }}</p>
        </div>
        <template v-slot:content>
                  <div class="m-4 subtitle is-6" style="display: flex; justify-content: space-between;">
                    <div style="display: flex; flex-direction: column; align-items: baseline;">
                        <p><b>Today</b></p>
                        <p><b>This Week</b></p>
                        <p><b>This Month</b></p>
                        <p><b>This Year</b></p>
                    </div>
                    <div style="display: flex; flex-direction: column;">
                        <p><b>{{ numberWithCommas(totalCounts.beneficiariesValues.todayBeneficiariesCount) }}</b></p>
                        <p><b>{{ numberWithCommas(totalCounts.beneficiariesValues.weekBeneficiariesCount) }}</b></p>
                        <p><b>{{ numberWithCommas(totalCounts.beneficiariesValues.monthBeneficiariesCount) }}</b></p>
                        <p><b>{{ numberWithCommas(totalCounts.beneficiariesValues.yearBeneficiariesCount)}}</b></p>
                    </div>
                  </div>
              </template>
        </b-tooltip>
      </div>
    </div>
  </template>
  
  <script>
  import NetworkManager from "@/network";
  
  const today = new Date()
  
  export default {
    name: "TransactionCounts",
    data() {
      return {
        totalCounts: {
          appUsersValues:{
            totalAppUserCount: 0.0,
            todayAppUserCount: 0.0,
            weekAppUserCount: 0.0,
            monthAppUserCount: 0.0,
            yearAppUserCount : 0.0
          },
          beneficiariesValues: {
            totalBeneficiariesCount: 0.0,
            todayBeneficiariesCount: 0.0,
            weekBeneficiariesCount: 0.0,
            monthBeneficiariesCount: 0.0,
            yearBeneficiariesCount : 0.0
        }
        }
      }
    },
    methods: {
      loadData: function () {
        const self = this
        setTimeout(() => {
  
          NetworkManager.apiRequest('api/dashboard/getAppUsersAndBeneficiariesCount', {}, function (e) {
            console.log(e);
            if (e.statusCode === 200) {
              self.totalCounts = e.data
            }
            else {
              //
            }
          })
        }, 200)
      }
    },
  
    mounted() {
      this.loadData()
    }
  }
  </script>
  
  <style scoped>
  .with-vertical-line {
    border-right: 2px solid #ccc;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  </style>
  